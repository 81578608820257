<template>
  <invoice-list-action-item
    v-if="itemList"
    :title="$t('download.xml')"
    @click="onDownload"
  >
    <template #icon>
      <v-progress-circular
        v-if="loading"
        color="primary"
        indeterminate
        size="64"
      />
      <icon-code-slash-outline v-else outlined />
    </template>
  </invoice-list-action-item>
  <v-btn
    v-else
    :loading="loading"
    color="primary"
    text
    @click="onDownload"
    v-text="$t('download')"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import IconCodeSlashOutline from "@/components/icons/IconCodeSlashOutline.vue";
import InvoiceListActionItem from "@/modules/invoices/components/InvoiceListActionItem.vue";
import useInvoice from "@/composables/invoice";

@Component({
  components: { InvoiceListActionItem, IconCodeSlashOutline },
})
export default class InvoiceXmlDownloadBtn extends Vue {
  @Prop([String, Number]) readonly uuid!: string;
  @Prop(String) readonly xml!: string;
  @Prop(String) readonly filename!: string;
  @Prop(Boolean) readonly itemList!: string;

  loading = false;

  async onDownload() {
    const { downloadXml } = useInvoice();
    this.loading = true;
    await downloadXml(this.uuid, this.filename);
    this.loading = false;
  }
}
</script>
