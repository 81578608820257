<template>
  <v-list-item :class="cssClass" v-bind="$attrs" v-on="$listeners">
    <v-list-item-icon v-if="hasIcon">
      <slot name="icon">
        <v-icon v-text="icon" :color="color" />
      </slot>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-if="hasTitle">
        <slot name="title">{{ title }}</slot>
      </v-list-item-title>
      <v-list-item-subtitle v-if="hasSubtitle">
        <slot name="subtitle">{{ subtitle }}</slot>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script lang="ts">
import { get } from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class InvoiceListActionItem extends Vue {
  @Prop(String) readonly icon!: string;
  @Prop(String) readonly title!: string;
  @Prop(String) readonly subtitle!: string;
  @Prop({ type: String, default: "primary" }) readonly color!: string;

  get hasIcon(): boolean {
    return !!this.icon || !!get(this.$slots, "icon");
  }

  get hasTitle(): boolean {
    return !!this.title || !!get(this.$slots, "title");
  }

  get hasSubtitle(): boolean {
    return !!this.subtitle || !!get(this.$slots, "subtitle");
  }

  get cssClass() {
    return `${this.color}--text`;
  }
}
</script>
